import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "顶层酵母提取"
    }}>{`顶层酵母提取`}</h1>
    <p>{`艾尔酵母是上层发酵的酵母，他们亲水的表层会使酵母附着在发酵产生的二氧化碳上，从而上升到液体的表层。`}</p>
    <p>{`上层提取酵母最重要的一点就是无菌环境`}</p>
    <h2 {...{
      "id": "优点"
    }}>{`优点`}</h2>
    <p>{`上层提取的酵母的质量比底层的要好：`}</p>
    <ol>
      <li parentName="ol">{`他们提取的时候是在活性最强的时候`}</li>
      <li parentName="ol">{`与底层细菌，啤酒花粉等的杂质相分离`}</li>
      <li parentName="ol">{`底层有更高的水压`}</li>
      <li parentName="ol">{`酿酒师的人工基因选择使酵母更倾向于浮于表面`}</li>
    </ol>
    <h2 {...{
      "id": "提取时间"
    }}>{`提取时间`}</h2>
    <p>{`发酵开始之后的 12 小时 - 3，4天`}</p>
    <h2 {...{
      "id": "要点"
    }}>{`要点`}</h2>
    <p>{`选择大的，平的，大开口的发酵罐
创造无菌的环境
对于顶层发酵很强烈的酵母，留有足够的上层空间
上层最表层有很多的蛋白质，在提取的时候我们希望去除尽量多的蛋白质，所以这一层最好给去掉
第二瓢和第三瓢含有活性最强的酵母
理论上任何器具都行，比如光滑表面的不锈钢勺
如果用勺子的话，不要全部打开盖子，尽量避免与外界空气的接触，确保附近没有大的空气流动`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      